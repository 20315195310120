import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import PhoneInput from "react-phone-number-input";
import InputFormik from "app/pages/.shared/form/InputFormik";
import { FormattedMessage } from "react-intl";
import { datadogRum } from "@datadog/browser-rum";
import "./PhoneIntl.scss";
import classNames from "classnames";

const PhoneIntl = ({
	id,
	name,
	isRequired,
	shop,
	setFieldValue,
	setFieldTouched,
	setFieldError,
	label,
	...restProps
}) => {
	const [field] = useField(name);
	const [lang, country] = shop.split("-");
	const [locale, setLocale] = useState();
	const [hasChanged, setHasChanged] = useState(false);
	const [currentCountryCode, setCurrentCountryCode] = useState();

	const handleBlur = () => {
		setHasChanged(false);
		if (typeof restProps?.onBlur === "function") {
			restProps.onBlur();
		}
	};

	const handleChange = useCallback(
		value => {
			setFieldTouched(name);
			setFieldValue(name, value);
			if (value !== "" && !hasChanged) {
				setFieldError(name);
				setHasChanged(true);
			}
		},
		[name, hasChanged]
	);

	useEffect(() => {
		import(/* webpackChunkName: "phone-country-[request]" */
		`react-phone-number-input/locale/${lang}.json`)
			.then(locale => {
				setLocale(locale.default);
			})
			.catch(err => {
				datadogRum.addError(err);
			});
	}, [lang]);

	const phoneInputClassName = classNames("phone-intl-input", {
		"phone-intl-input--SA": currentCountryCode === "SA",
	});

	return (
		<PhoneInput
			id={id}
			name={name}
			className={phoneInputClassName}
			displayInitialValueAsLocalNumber
			inputComponent={InputFormik}
			defaultCountry={country}
			value={field.value}
			labels={locale}
			onCountryChange={setCurrentCountryCode}
			shouldSanitize={false}
			onChange={handleChange}
			isRequired={isRequired}
			numberInputProps={{
				type: "tel",
				label: label,
				maxLength: "50",
			}}
			onBlur={handleBlur}
			{...restProps}
		/>
	);
};

PhoneIntl.defaultProps = {
	label: <FormattedMessage id="phone.label" />,
};

PhoneIntl.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	isRequired: PropTypes.bool,
	shop: PropTypes.string,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	setFieldError: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default PhoneIntl;
